var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fd-image-uploader fd-validation",class:{ error: _vm.hasError }},[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"row mxn-1 myn-1"},[_c('cool-light-box',{attrs:{"items":_vm.renderingData.slice(0, _vm.maxImage).map(function (img) {
          return ("" + (_vm.$getFileURL(img)));
        }),"index":_vm.imgIndex,"slideshow":false},on:{"close":function($event){_vm.imgIndex = null}}}),_vm._l((_vm.renderingData.slice(0, _vm.maxImage)),function(obj,index){return _c('div',{key:("image" + index),class:_vm.imgWrapperClass},[_c('div',{staticClass:"image-container"},[_c('img',{staticClass:"image cursor-pointer",attrs:{"src":("" + (_vm.$getFileURL(obj)))},on:{"click":function($event){_vm.imgIndex = index}}}),_c('div',{staticClass:"cancelButton",on:{"click":function () {
              _vm.imageDelete(obj);
            }}},[_c('i',{staticClass:"flaticon-close"})])])])}),(_vm.isAddImageAvailable())?_c('div',{class:_vm.imgWrapperClass},[_c('div',{staticClass:"image-container"},[(_vm.isLoading)?_c('spinner',{staticClass:"img-loading"}):_c('div',[_c('span',{staticClass:"filetype-hint"},[_vm._v(".jpg, .jpeg, and .png only")]),_c('span',{staticClass:"max-file-size-hint"},[_vm._v("Max: "+_vm._s(_vm.maxSize)+" MB")]),_c('div',{staticClass:"addButton"},[_c('i',{staticClass:"flaticon-add"})]),_c('input',{attrs:{"type":"file","accept":"image/x-png,image/jpeg","multiple":_vm.multiple,"max":_vm.maxImage},on:{"change":_vm.imageChoosen}})])],1)]):_vm._e()],2),(_vm.hasError)?_c('div',{staticClass:"errorMsg mt-1",class:{ 'shake-horizontal': _vm.hasError }},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),(_vm.multiple)?_c('p',{staticClass:"maxImg"},[_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.renderingData.length))]),_vm._v("/"+_vm._s(_vm.maxImage)+" ")]):_c('p',{staticClass:"maxImg"},[_vm._v("Single Image Only")]),(_vm.cropperEnabled)?_c('modal',{attrs:{"fullscreen":"","persistent":""},model:{value:(_vm.showCropper),callback:function ($$v) {_vm.showCropper=$$v},expression:"showCropper"}},[_c('cropper-content',_vm._b({attrs:{"toCrop":_vm.toCrop,"toCropList":_vm.toCropList,"stencil-props":_vm.stencilProps},on:{"update:toCrop":function($event){_vm.toCrop=$event},"update:to-crop":function($event){_vm.toCrop=$event},"update:toCropList":function($event){_vm.toCropList=$event},"update:to-crop-list":function($event){_vm.toCropList=$event},"close":_vm.closeCropper,"open":function($event){_vm.showCropper = true},"append":_vm.appendToCrop,"upload":_vm.uploadCropped}},'cropper-content',{
        multiple: _vm.multiple,
        maxImage: Number(_vm.maxImage) - _vm.renderingData.length,
        maxSize: _vm.maxSize
      },false))],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }