<template>
  <div class="fd-image-uploader fd-validation" :class="{ error: hasError }">
    <!-- Label -->
    <label class="label"
      >{{ label }} <span style="color: red" v-if="required">*</span>
    </label>

    <div class="row mxn-1 myn-1">
      <cool-light-box
        :items="
          renderingData.slice(0, maxImage).map((img) => {
            return `${$getFileURL(img)}`;
          })
        "
        :index="imgIndex"
        @close="imgIndex = null"
        :slideshow="false"
      >
      </cool-light-box>

      <!-- Images Preview -->
      <div
        v-for="(obj, index) in renderingData.slice(0, maxImage)"
        :key="`image${index}`"
        :class="imgWrapperClass"
      >
        <div class="image-container">
          <img
            class="image cursor-pointer"
            :src="`${$getFileURL(obj)}`"
            @click="imgIndex = index"
          />

          <div
            class="cancelButton"
            @click="
              () => {
                imageDelete(obj);
              }
            "
          >
            <i class="flaticon-close"></i>
          </div>
        </div>
      </div>

      <!-- The Uploader -->
      <div :class="imgWrapperClass" v-if="isAddImageAvailable()">
        <div class="image-container">
          <spinner v-if="isLoading" class="img-loading"></spinner>
          <div v-else>
            <span class="filetype-hint">.jpg, .jpeg, and .png only</span>
            <span class="max-file-size-hint">Max: {{ maxSize }} MB</span>
            <div class="addButton">
              <i class="flaticon-add"></i>
            </div>

            <input
              type="file"
              accept="image/x-png,image/jpeg"
              @change="imageChoosen"
              :multiple="multiple"
              :max="maxImage"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="hasError"
      class="errorMsg mt-1"
      :class="{ 'shake-horizontal': hasError }"
    >
      {{ errorMessage }}
    </div>

    <!-- Max Image Indicator -->
    <p v-if="multiple" class="maxImg">
      <span class="bold">{{ renderingData.length }}</span
      >/{{ maxImage }}
    </p>
    <p v-else class="maxImg">Single Image Only</p>

    <!-- Croppper -->
    <modal v-if="cropperEnabled" v-model="showCropper" fullscreen persistent>
      <cropper-content
        :toCrop.sync="toCrop"
        :toCropList.sync="toCropList"
        :stencil-props="stencilProps"
        v-bind="{
          multiple,
          maxImage: Number(maxImage) - renderingData.length,
          maxSize: maxSize
        }"
        @close="closeCropper"
        @open="showCropper = true"
        @append="appendToCrop"
        @upload="uploadCropped"
      ></cropper-content>
    </modal>
  </div>
</template>

<script>
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";
import { uploadImage, uploadWatermarkImage } from "@/api/v1/misc/imageUpload";
import { elipsisMiddle } from "@/utils/string";
import { fileToDataURL, dataURLtoFile } from "@/utils/image";

import Modal from "@/components/GlobalComponents/ModalBox/Modal";
import CropperContent from "@/components/GlobalComponents/ImageUploader/CropperContent";

import "boxicons/css/boxicons.min.css";

const KB = 1024;
const MB = KB * 1024;

export default {
  name: "fd-image-uploader",
  mixins: [],
  props: {
    value: {
      type: Array,
      default: () => []
    },
    label: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    maxImage: {
      type: Number,
      default: 12
    },
    maxSize: {
      // In MB
      type: Number,
      default: 5
    },
    watermark: {
      type: Boolean,
      default: false
    },
    imgWrapperClass: {
      type: String,
      default: "xl-col-2 lg-col-3 md-col-4 sm-col-4 col-6 px-1 py-1"
    },
    // Croppper Settings
    cropperEnabled: {
      type: Boolean,
      default: false
    },
    stencilProps: {
      type: Object
    }
  },
  components: {
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner"),
    Modal,
    CropperContent
  },
  data() {
    return {
      isLoading: false,
      imgIndex: null,
      staged: [],

      showCropper: false,
      toCropList: [],
      toCrop: null,

      hasError: false,
      errorMessage: ""
    };
  },
  computed: {
    computeInputRequired() {
      if (this.required && this.value.length <= 0) {
        return true;
      }

      return false;
    },
    validators() {
      return this.required ? [required] : [];
    },
    renderingData() {
      // Null checking purpose. (If null, then convert to empty array)
      if (this.value == null) {
        return [];
      } else {
        let temp = this.value.concat();
        for (let x = 0; x < temp.length; x++) {
          if (temp[x] == null) {
            temp.splice(x, 1);
          }
        }
        return temp;
      }
    }
  },
  watch: {
    value: {
      handler() {
        this.setLoading(false);
      },
      deep: true
    }
  },
  mounted() {},
  methods: {
    setLoading(bool) {
      this.isLoading = bool;
      this.$emit("loading", bool);
    },

    async imageChoosen(e) {
      this.hasError = false;

      for (let file of e.target.files) {
        this.setLoading(true);

        try {
          let numberOfImages = this.value.length + this.staged.length;
          if (!this.validateMaxImage(numberOfImages + 1)) {
            break;
          }
          if (!this.validateFormat(file)) {
            continue;
          }
          if (!this.validateMaxSize(file)) {
            continue;
          }

          this.staged.push(file);

          if (this.cropperEnabled) {
            this.openCropper(file);
          } else {
            // Upload the image to server
            await this.imageUpload(file);
          }

          continue;
        } catch (error) {
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error Uploading",
            text: `Something went wrong uploading ${file.name}. Please try again later.`
          });
          this.$emit("error", error, file.name);
          continue;
        }
      }
      this.setLoading(false);
      e.target.value = null;
    },

    async imageUpload(image) {
      let data = {};
      if (this.watermark) {
        data = await uploadWatermarkImage(image);
      } else {
        data = await uploadImage(image);
      }
      // Emits new image array
      let newArray = [...this.value, data];
      this.staged = [];
      this.$emit("input", newArray);
      this.$emit("added", data);
    },

    async openCropper(file) {
      this.showCropper = true;
      await this.appendToCrop(file);
      this.toCrop = this.toCropList[0];
    },
    closeCropper() {
      this.staged = [];
      this.showCropper = false;
    },

    async appendToCrop(file) {
      let toCrop = await fileToDataURL(file);
      this.toCropList.push(toCrop);
    },

    async uploadCropped(loading) {
      loading(true);
      await this.toCropList.forEach(async (image) => {
        this.setLoading(true);
        let timestamp = this.$dayjs().valueOf();
        let file = dataURLtoFile(image, `cropped-${timestamp}`);
        await this.imageUpload(file);
        this.setLoading(false);
      });
      this.toCropList = [];
      loading(false);
      this.showCropper = false;
    },

    imageDelete(obj) {
      // Create an array without the removed image
      let newArray = this.value.filter((image) => {
        return image.fileName !== obj.fileName;
      });

      // Update v-model with latest image array data
      this.$emit("input", newArray);
      // On delete, the image object will be returned
      this.$emit("delete", obj);
    },

    /**
     * Checks if still can add image.
     * @return Boolean
     */
    isAddImageAvailable() {
      if (this.multiple) {
        return this.renderingData.length < this.maxImage ? true : false;
      } else {
        return this.renderingData.length < 1;
      }
    },

    validateMaxImage(length) {
      if (length > this.maxImage) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: `You can only upload ${this.maxImage} images. The rest will be automatically ignored.`
        });
        return false;
      }
      return true;
    },

    validateMaxSize(file) {
      if (file.size > this.maxSize * MB) {
        this.$notify({
          group: "alert",
          type: "error",
          title: `File size limit exceeded (${this.maxSize}MB)`,
          text: `"${elipsisMiddle(file.name)}" has exceeded file size limit.`
        });

        return false;
      }
      return true;
    },

    validateFormat(file) {
      const allowedExtensions = /^(jpg|jpeg|png)$/i;
      let extension = file.name.substr(file.name.lastIndexOf(".") + 1);
      if (!allowedExtensions.exec(extension)) {
        this.$notify({
          group: "alert",
          type: "error",
          title: `${elipsisMiddle(file.name)}: File format is not allowed`,
          text: `Only .jpg, .jpeg, and .png is allowed`
        });

        return false;
      }
      return true;
    },

    validate() {
      return this.runValidators();
    },
    runValidators() {
      let valid = true;

      // Validate field only if it's required or filled.
      if (!this.isRequiredOrFilled()) {
        valid = true;
      }
      for (const validator of this.validators) {
        if (!this.runValidator(validator)) {
          valid = false;
          break;
        }
      }
      return valid;
    },
    runValidator(validator) {
      let result = validator(this.value);
      if (result === true) {
        return true;
      } else {
        this.hasError = true;
        this.errorMessage = result;
      }
    },
    hasRequired() {
      return (
        // Cannot use function name check because the function name will be changed during production mode
        this.validators.filter((v) => Object.is(v, required)).length > 0
      );
    },
    isRequiredOrFilled() {
      return this.hasRequired() || this.value !== "";
    }
  }
};
</script>

<style lang="scss">
.fd-image-uploader {
  .maxImg {
    background: #eee;
    border-radius: 4px;
    width: fit-content;
    padding: 6px 12px;
    margin: 5px 0px;
    text-transform: uppercase;
  }

  .image-container {
    @include flex(row, center, center);
    border: 1px solid #ddd;
    position: relative;
    &:hover {
      .cancelButton {
        visibility: visible;
        opacity: 0.5;
      }
      .addButton {
        opacity: 1;
      }
    }
    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }

    img.image {
      display: block;
      width: 100%;
      @include image(cover);
      position: absolute;
      bottom: 0;
      left: 0;
      top: 0;
    }
    input {
      position: absolute;
      cursor: pointer;
      height: 100%;
      width: 100%;
      opacity: 0;
      bottom: 0;
      left: 0;
      top: 0;
    }
    .cancelButton {
      background-color: rgba(black, 0.5);
      visibility: hidden;
      position: absolute;
      border-radius: 50%;
      transition: 0.3s;
      cursor: pointer;
      opacity: 0;
      right: 4px;
      top: 4px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media #{$breakpoint-down-sm} {
        opacity: 1;
        visibility: visible;
      }
      &:hover {
        opacity: 1;
      }
      i {
        font-size: 12px;
        color: white;
      }
    }
    .addButton {
      @include flex(row, center, center);
      background-color: rgba(black, 0.5);
      transform: translate(-50%, -50%);
      border-radius: 50%;
      position: absolute;
      transition: 0.3s;
      cursor: pointer;
      opacity: 0.5;
      height: 65px;
      width: 65px;
      left: 50%;
      top: 50%;
      i {
        font-size: 32px;
        color: white;
      }
    }
    .max-file-size-hint {
      position: absolute;
      height: 25px;
      bottom: 0;
      width: 100%;
      left: 0;
      text-align: center;
      background: #00000015;
      color: #505050;
      align-items: center;
      display: flex;
      justify-content: center;
    }
    .filetype-hint {
      position: absolute;
      background-color: #ddd;
      padding: 4px 12px;
      width: 100%;
      top: 0;
      width: 100%;
      left: 0;
      text-align: center;
      background: #00000015;
      color: #505050;
      align-items: center;
      display: flex;
      justify-content: center;
      font-size: 12px;
    }

    .img-loading {
      position: absolute;
    }
  }

  &.error {
    $errorColor: #db4141;

    .errorMsg {
      background: $errorColor;
      color: white;
      padding: 6px 12px;
      border-radius: 5px;
      width: fit-content;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        left: 10px;
        top: -5px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid $errorColor;
        display: block;
        z-index: 1;
      }

      &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        left: 10px;
        top: -5px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid $errorColor;
        z-index: 0;
      }
    }
  }
}
</style>
