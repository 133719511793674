<template>
  <modal-content class="cropper-content card">
    <template #body>
      <div class="cropper-container">
        <cropper
          v-if="cropMode"
          ref="cropper"
          class="cropper"
          :src="toCrop"
          :stencil-props="stencilProps"
          :default-size="defaultSize"
        />
        <div
          v-else
          class="d-flex justify-content-center"
          :style="{ height: '450px' }"
        >
          <img :src="toCrop" />
        </div>
      </div>
      <div
        v-if="isLoading"
        class="d-flex justify-content-center align-items-center my-6"
      >
        <spinner></spinner>
      </div>
      <div v-else>
        <!-- ============================ Toolbar ============================ -->
        <div class="toolbar d-flex justify-content-center my-5">
          <fd-button
            class="circle mx-1"
            :class="{ main: cropMode }"
            v-tooltip="'Toggle Crop Mode'"
            @click="cropMode = !cropMode"
          >
            <i class="fas fa-crop"></i>
          </fd-button>
          <div v-if="cropMode">
            <fd-button
              class="circle mx-1"
              v-tooltip="'Flip Horizontal'"
              @click="flipImageH"
              ><i class="bx bx-reflect-vertical"></i>
            </fd-button>
            <fd-button
              class="circle mx-1"
              v-tooltip="'Flip Vertical'"
              @click="flipImageV"
              ><i class="bx bx-reflect-horizontal"></i>
            </fd-button>
            <fd-button
              class="circle mx-1"
              v-tooltip="'Rotate Left'"
              @click="rotateImageL"
              ><i class="bx bx-rotate-left"></i>
            </fd-button>
            <fd-button
              class="circle mx-1"
              v-tooltip="'Rotate Right'"
              @click="rotateImageR"
              ><i class="bx bx-rotate-right"></i>
            </fd-button>
            <fd-button class="round bordered main mx-1" @click="cropImage">
              <i class="fas fa-check mr-1"></i> Done
            </fd-button>
          </div>
        </div>

        <!-- ========================= Preview Image ========================= -->
        <div class="preview-list d-flex justify-content-center m-2">
          <span
            v-for="(image, index) in toCropList"
            :key="index"
            class="preview-list-img-wrapper"
            @click="selectToCrop(index)"
          >
            <div v-if="toCropList[index] == toCrop" class="active-state"></div>
            <span class="overlay d-flex justify-content-end">
              <fd-button
                class="xs bg-grey-600 fg-white circle m-1"
                @click.stop="removeImage(index)"
              >
                <i class="fas fa-times"></i>
              </fd-button>
            </span>
            <img :src="image" class="preview-list-img" />
          </span>
          <label
            v-if="multiple && toCropList.length < maxImage"
            class="add-file-button"
          >
            <input
              type="file"
              accept="image/x-png,image/jpeg"
              @change="appendImage"
              :multiple="multiple"
              :maxImage="maxImage - toCropList.length"
            />
            <div class="add-file-button-content">
              <i class="fas fa-plus"></i>
            </div>
          </label>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end p-2">
        <fd-button class="mx-1" @click="closeCropper" :disabled="isLoading">
          Cancel
        </fd-button>
        <fd-button
          class="main mx-1"
          @click="confirmUpload"
          :disabled="isLoading"
        >
          Confirm
        </fd-button>
      </div>
    </template>
  </modal-content>
</template>

<script>
import ModalContent from "@/components/GlobalComponents/ModalBox/ModalContent";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { elipsisMiddle } from "@/utils/string";

const KB = 1024;
const MB = KB * 1024;

export default {
  components: {
    ModalContent,
    Cropper,
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner")
  },
  mixins: [],
  props: {
    toCrop: {
      default: null
    },
    toCropList: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    },
    maxImage: {
      type: [Number, String]
    },
    maxSize: {
      type: [Number, String]
    },
    stencilProps: {
      type: Object,
      default: () => ({
        movable: true,
        resizable: true
      })
    }
  },
  data: function () {
    return {
      isLoading: false,
      cropMode: false,

      defaultSize({ imageSize, visibleArea }) {
        return {
          width: (visibleArea || imageSize).width,
          height: (visibleArea || imageSize).height
        };
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    setLoading(bool) {
      this.isLoading = bool;
    },
    appendImage(e) {
      this.isLoading = true;

      for (let file of e.target.files) {
        if (!this.validateMaxImage(this.toCropList.length + 1)) {
          break;
        }
        if (!this.validateFormat(file)) {
          continue;
        }
        if (!this.validateMaxSize(file)) {
          continue;
        }

        this.$emit("append", file);
      }

      this.isLoading = false;
      e.target.value = null;
    },
    removeImage(toRemoveIndex) {
      let toCropIndex = this.toCropList.findIndex((val) => val == this.toCrop);

      this.$emit(
        "update:toCropList",
        this.toCropList.filter((val, index) => index !== toRemoveIndex)
      );

      this.$nextTick(() => {
        // Close modal if last item is removed
        if (this.toCropList.length < 1) {
          this.closeCropper();
        }
        // Set toCrop to first item if active item is removed
        if (toCropIndex == toRemoveIndex) {
          this.$emit("update:toCrop", this.toCropList[0]);
        }
      });
    },
    selectToCrop(index) {
      this.$emit("update:toCrop", this.toCropList[index]);
    },
    closeCropper() {
      this.$emit("update:toCrop", null);
      this.$emit("update:toCropList", []);
      this.$emit("close");
    },
    flipImageH() {
      this.$refs.cropper.flip(true, false);
    },
    flipImageV() {
      this.$refs.cropper.flip(false, true);
    },
    rotateImageL() {
      this.$refs.cropper.rotate(-90);
    },
    rotateImageR() {
      this.$refs.cropper.rotate(90);
    },
    cropImage() {
      const { canvas } = this.$refs.cropper.getResult();
      const croppedImage = canvas.toDataURL("image/jpeg");

      let toCropIndex = this.toCropList.findIndex((val) => val === this.toCrop);
      let newList = this.toCropList.map((item, index) =>
        index == toCropIndex ? croppedImage : item
      );

      this.cropMode = false;

      this.$emit("update:toCrop", croppedImage);
      this.$emit("update:toCropList", newList);
    },
    confirmUpload() {
      this.$emit("upload", this.setLoading);
    },

    // Validation
    validateMaxImage(length) {
      if (length > this.maxImage) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: `You can only upload ${this.maxImage} images. The rest will be automatically ignored.`
        });
        return false;
      }
      return true;
    },

    validateMaxSize(file) {
      if (file.size > this.maxSize * MB) {
        this.$notify({
          group: "alert",
          type: "error",
          title: `File size limit exceeded (${this.maxSize}MB)`,
          text: `"${elipsisMiddle(file.name)}" has exceeded file size limit.`
        });

        return false;
      }
      return true;
    },

    validateFormat(file) {
      const allowedExtensions = /^(jpg|jpeg|png)$/i;
      let extension = file.name.substr(file.name.lastIndexOf(".") + 1);
      if (!allowedExtensions.exec(extension)) {
        this.$notify({
          group: "alert",
          type: "error",
          title: `${elipsisMiddle(file.name)}: File format is not allowed`,
          text: `Only .jpg, .jpeg, and .png is allowed`
        });

        return false;
      }
      return true;
    }
  }
};
</script>

<style lang="scss">
.cropper-content {
  // Cropper
  .cropper-container .cropper {
    height: 450px;
  }

  // Preview List
  .preview-list {
    overflow-x: auto;
    @mixin preview-item {
      $size: 80px;
      width: $size;
      height: $size;
      border-radius: 8px;
      @media #{$breakpoint-down-sm} {
        $size: 64px;
        width: $size;
        height: $size;
      }
    }
    .preview-list-img-wrapper {
      cursor: pointer;
      margin: 4px 8px;
      position: relative;
      &:hover {
        .overlay {
          opacity: 1;
        }
      }
      .overlay {
        opacity: 0;
        @include preview-item;
        position: absolute;
        background: linear-gradient(to bottom, #00000050 25%, transparent);
      }

      // Active State
      .active-state {
        @include preview-item;
        position: absolute;
        border: solid 4px $color-main;
      }
      .preview-list-img {
        @include preview-item;
        object-fit: cover;
      }
    }
    .add-file-button {
      @include preview-item;
      border: solid 2px #ddd;
      margin: 4px 8px;

      input[type="file"] {
        display: none;
      }

      .add-file-button-content {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        i {
          font-size: 1.5em;
          color: #666;
        }
      }

      &:hover {
        cursor: pointer;
        border: solid 2px $color-main;
      }
    }
  }
}
</style>
